import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {Input, Button} from 'reactstrap';
// import heroImage from './ew_slide.jpg';

import heroImage from './zantelbanner-min.jpeg';



class LandingPage extends Component {

    state={}
    componentDidMount() {
        // const token = localStorage.getItem('token');
        // if (token != null && token.length > 0) {
        //     this.setState({redirect: '/dashboard'});
        // }
    }

   NmbBlue = {
        background: "#005aa9"
    }

    orangeFont = {
        color: "#8cc540"
    }

    FeatureIcon = {
        borderRadius: "50%",
        fontSize: "1.25rem",
        backgroundColor: "#8cc540",
        color: "white",
        width: "2.5em",
        height: "2.5em",
        lineHeight: "1.5",
        padding: "0.45em"
    }

    IntroText = {
        fontSize: "18px",
        lineHeight: "2em"
    }

    IntroHeader = {
        fontSize: "36px",
        lineHeight: "1.2em",
        color: "#aa0002",
        fontWeight: "700"
    }

    ParaText = {
        fontSize: "16px",
        marginBottom: "30px",
        lineHeight: "2em"
    }

    FrontButton = {
        fontSize: "16px",
        minWidth: "270px",
        padding: "12px"
    }

    NavLink = {
        fontSize: "16px",
    }

    ContactList = {
        fontSize: "15px",
        lineHeight: "1.8em"  
    }

    HeroHeader = {
        backgroundImage: `linear-gradient(to top, rgba(35, 40, 85, 0.45), rgba(40, 40, 65, 0.25)), url(${heroImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        minHeight: "600px"
    }

    HeroText = {
        width: "100%",
        textAlign: "center",
        marginTop: "180px",
        marginBottom: "140px"
    }

    HeroSubText = {
        fontSize: "24px",
        marginBottom: "40px",
        marginTop: "20px",
        fontWeight: "500"
    }

    ViewLogin = () => {
        return this.props.history.push('/login')
    }

    ViewSignUp = () => {
        return this.props.history.push('/register')
    }

   

    onSubmit = e => {
        e.preventDefault();
        this.ViewLogin();
    }

    render() {
        const year = new Date().getFullYear()
        return (
            <div className="bg-white">
               <header>
                    { /* START Top Navbar */}
                    <nav className="navbar topnavbar py-2 px-5" >
                        { /* START navbar header */}
                        <div className="navbar-header">
                            <a className="navbar-brand" href="#/">
                                <div className="brand-logo">
                                    <img className="img-fluid" src="img/logo-zantel.png" alt="App Logo" style={{height:'45px'}} />
                                </div>
                                <div className="brand-logo-collapsed">
                                    <img className="img-fluid" src="img/logo-zantel.png" alt="App Logo" />
                                </div>
                            </a>
                        </div>
                        { /* END navbar header */}

                        <div className="navbar-nav flex-row">
                            <ul className="nav mx-3 px-3">
                                <li className="nav-item mx-3">
                                    <a className="nav-link text-white" href="#features" style={this.NavLink}>Features</a>
                                </li>
                                <li className="nav-item mx-3">
                                    <a className="nav-link text-white" href="#about" style={this.NavLink}>About</a>
                                </li>
                                <li className="nav-item mx-3">
                                    <a className="nav-link text-white" href="#contact" style={this.NavLink}>Contact Us</a>
                                </li>
                            </ul>
                            <Button outline onClick={this.ViewLogin} color="success" className="btn-pill mr-3">Login</Button>
                            <Button outline color="success" onClick={this.ViewSignUp} className="btn-pill mr-3">Sign Up</Button>
                        </div>
                    </nav>
                </header>

                <div className="container-fluid px-0">
                    <div style={this.HeroHeader} className="py-5">
                        <div style={this.HeroText} className="px-md-5 px-3">
                            <h1 className="text-white mb-3">Robust & efficient messaging system</h1>
                            <p style={this.HeroSubText} className="text-white">You can now manage all your SMS campaigns online with the SMS Gateway.
                            </p>                           
                        </div>
                    </div>
                </div>

                <div className="container-fluid text-dark px-0 mt-5">
                    <h2 className="text-center my-md-4 my-2" id="features"><strong>System Features</strong></h2>
                    <div className="row no-gutters m-lg-5 m-3">
                        <div className="col-sm-6 col-lg-4 text-center px-md-4 px-2 py-3">
                            <span className="fas fa-envelope mb-3" style={this.FeatureIcon}></span>
                            <h4>Bulk SMS Sending</h4>
                            <p style={this.ParaText}>ThYou can send Bulk SMS up to 200k at a time without any downtime or crashing.</p>
                        </div>
                        <div className="col-sm-6 col-lg-4 text-center px-md-4 px-2 py-3">
                            <span className="fa fa-calendar-alt mb-3" style={this.FeatureIcon}></span>
                            <h4>Scheduled SMS</h4>
                            <p style={this.ParaText}>It's easy to schedule messages that need to be sent at a later time with the Scheduled SMS Feature.</p>
                        </div>
                        <div className="col-sm-6 col-lg-4 text-center px-md-4 px-2 py-3">
                            <span className="fa fa-link mb-3" style={this.FeatureIcon}></span>
                            <h4>Customizable Sender Name</h4>
                            <p style={this.ParaText}>Choose customized names to appear as the sender when you send messagaes to stakeholders and customers.</p>
                        </div>
                        <div className="col-sm-6 col-lg-4 text-center px-md-4 px-2 py-3">
                            <span className="fa fa-chart-line mb-3" style={this.FeatureIcon}></span>
                            <h4>Elegant analytics and reports</h4>
                            <p style={this.ParaText}>The system assists in decision making since it catalogs and analyzes relevant information.</p>
                        </div>
                        <div className="col-sm-6 col-lg-4 text-center px-md-4 px-2 py-3">
                            <span className="fa fa-users mb-3" style={this.FeatureIcon}></span>
                            <h4>Multi-User Platform</h4>
                            <p style={this.ParaText}>The feature allows different types of users to efficiently access the platform with different privileges.</p>
                        </div>
                        <div className="col-sm-6 col-lg-4 text-center px-md-4 px-2 py-3">
                            <span className="fa fa-money-bill-wave mb-3" style={this.FeatureIcon}></span>
                            <h4>Guaranteed Uptime</h4>
                            <p style={this.ParaText}>Through fast and reliable background services, we guarantee a minimum uptime of about 90%.</p>
                        </div>
                    </div>
                </div>

                <div className="text-center container-fluid py-5 px-lg-5 bg-dark"  id="about">
                    <h1 className="text-white my-3"><strong>About the System</strong></h1>
                    <div className="row py-lg-4 py-2 justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <p className="text-white" style={this.ParaText}>This is a modern, robust, online messaging gateway that is fully optimized to provide for bulk messaging campaigns. The platform is built using modern web technologies that enhance security and performance without compromising user experience.</p>
                        </div>
                    </div>
                </div>

                <div className="container-fluid text-dark px-0">
                    <div className="text-center my-md-5 my-3" id="contact">
                        <h1 className="mb-4 pt-2">Get in touch</h1>
                        <h4 className="px-2">Contact us to find out more about the messaging platform.</h4>
                    </div>
                    <hr className="my-md-4 my-2"/>
                    <div className="row px-md-4 px-2 mx-md-4 pt-2 pb-4 mb-3 no-gutters">
                        <div className="col-md-4 px-md-3 px-2 py-md-3 py-2 ml-md-3">
                            <h4><span className="fa fa-home" style={this.orangeFont}></span> &nbsp; Address:</h4>
                            <ul className="list-unstyled ml-2" style={this.ContactList}>
                                <li>Zantel HQ,</li>
                                <li>Head Office,</li>
                                <li>Mwai Kibaki Road,</li>
                                <li>P.O. Box 9213 Dar es Salaam, Tanzania,</li>
                                <li>info@zantel.co.tz</li>
                            </ul>
                            <h4><span className="fa fa-clock" style={this.orangeFont}></span> &nbsp; Head Office Working Hours:</h4>
                            <ul className="list-unstyled ml-2" style={this.ContactList}>
                                <li>Monday - Friday,</li>
                                <li>0800hrs to 1700hrs,</li>
                            </ul>
                            <h4 className="mt-md-4 mt-3"><span className="fa fa-phone-alt" style={this.orangeFont}></span> &nbsp; TEL:</h4>
                            <ul style={this.ContactList}>
                                <li>+255 775 000000</li>
                            </ul>
                        </div>
                        <div className="col-md-7 px-md-3">
                            <div className="bg-light p-md-4 p-2">
                                <form className="mb-3 p-md-2 p-1"  name="formContact" onSubmit={this.onSubmit}>
                                    <div className="form-group px-2">
                                        <label>Full name</label>
                                        <div className="input-group with-focus">
                                            <Input type="text" name="name"
                                                className="border-right-0 form-control form-control-lg rounded-0"
                                                placeholder="Enter full name" />
                                            <div className="input-group-append">
                                                <span className="input-group-text bg-transparent border-left-0">
                                                    <em className="fa fa-user"></em>
                                                </span>
                                            </div>
                                        </div>
                                    </div>  
                                    <div className="form-group px-2">
                                        <label>Email address</label>
                                        <div className="input-group with-focus">
                                            <Input type="email" name="email"
                                                className="border-right-0 form-control form-control-lg rounded-0"
                                                placeholder="Enter email address" />
                                            <div className="input-group-append">
                                                <span className="input-group-text bg-transparent border-left-0">
                                                    <em className="fa fa-envelope"></em>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group px-2">
                                        <label>Phone number</label>
                                        <div className="input-group with-focus">
                                            <Input type="tel" name="phone"
                                                className="border-right-0 form-control form-control-lg rounded-0"
                                                placeholder="Enter phone number" />
                                            <div className="input-group-append">
                                                <span className="input-group-text bg-transparent border-left-0">
                                                    <em className="fa fa-phone-alt"></em>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group px-2">
                                        <label>Message</label>
                                        <Input type="textarea" name="message"
                                            className="form-control rounded-0"
                                            rows = "4"
                                            placeholder="Message" />
                                    </div>
                                    <div className="px-2">
                                        <button className="btn btn-danger mt-3" type="submit">Send message</button>
                                    </div>                           
                                </form>
                            </div>
                        </div>
                    </div>
                    
                    <div className="p-3 text-center">
                        <span className="mr-2">&copy;</span>
                        <span>{year}</span>
                        <span className="mx-2">-</span>
                        <span>E-SMS</span>
                        <br />
                        <span>ZANTEL MESSAGING SYSTEM</span>
                    </div>
                </div>
            </div>
            
        );
    }
}

export default LandingPage;
